<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{title}} Course results
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{title}} Course results
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="3">
                  <v-text-field
                      v-model="search.candidate"
                      v-on:keyup.enter="getCandidateOnlineCourseResults"
                      @input="search.candidate = $event !== null ? $event : ''"
                      label="Search by enrolment key or candidate name"
                      outlined
                      dense
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense
                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn @click.prevent="getCandidateOnlineCourseResults()" class="btn btn-primary"
                         :loading="loading"
                         style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Attempt ID</th>
                  <th class="px-3">Course</th>
                  <th class="px-3">Start time</th>
                  <th class="px-3">Completion time</th>
                  <th class="px-3">Result date</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in candidate_online_course_results" :key="index">


                    <td>
                      <div class="">
                        {{ item.candidate ?  item.candidate.first_name : '--'}}
                      </div>
                      <a href="#" @click="getCandidateSummary(item.candidate.exam_key)" class="mb-1">
                        {{ item.candidate ?  item.candidate.exam_key : ''}}
                      </a>
                    </td>
                    <td class="text-left">
                      {{ item.attempt_id }}
                    </td>
                    <td>
                      {{ item.course ? item.course.name : '--' }}
                    </td>
                    <td class="text-left">
                      {{ item.formatted_start_time  ? item.formatted_start_time : '--' }}
                    </td>
                    <td class="text-left">
                      {{ item.formatted_completion_time ? item.formatted_completion_time : '--' }}
                    </td>
                    <td class="text-left">
                      {{ item.formatted_result_available_date ? item.formatted_result_available_date : '--'  }}
                    </td>
                  </tr>
                </template>
                <tr v-if="candidate_online_course_results.length == 0">
                  <td colspan="8">No results available</td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="candidate_online_course_results.length > 0"
                  class="pull-right mt-7"
                  @input="getCandidateOnlineCourseResults"
                  v-model="page"
                  :disabled="loading"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import CandidateOnlineCourseResult from "@/services/candidate/online-course-result/CandidateOnlineCourseResult";

const candidateOnlineCourseResult = new CandidateOnlineCourseResult();

export default {

  data(){
    return{
      candidate_online_course_results: [],
      total: null,
      title: '',
      perPage: null,
      page: null,
      menuStartDate: false,
      menuEndDate: false,
      loading: false,
      search: {
        candidate: '',
        start_date: '',
        end_date: '',
      },
      symbols: [
        { name: "Equal", value: "=" },
        { name: "Greater Than", value: ">" },
        { name: "Smaller Than", value: "<" },
        { name: "Greater Than or Equal To", value: ">=" },
        { name: "Smaller Than or Equal To", value: "<=" },
      ]
    }
  },
  methods:{
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
    getCandidateOnlineCourseResults(){
      if(this.search.end_date < this.search.start_date) {
        this.$snotify.error("View up to date must be greater than view from date");
        return;
      }
      this.loading = true;
      candidateOnlineCourseResult
          .paginate(this.search, this.page)
          .then(response => {
            this.candidate_online_course_results = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
  },
  mounted(){
    this.getCandidateOnlineCourseResults();
  }
}
</script>